<template>
  <div>
    <div class="maintitle">服务项目管理</div>
    <el-divider></el-divider>
    <el-row class="text-left"
      ><el-button @click="addnewtype">添加新服务</el-button></el-row
    >
    <el-table
        :data="list"
        row-key="id"
        border
        lazy
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
    >
      <el-table-column prop="type" label="服务项目" width="180"></el-table-column>
      <el-table-column :formatter="formatStatus" label="状态" width="100" align="center"></el-table-column>
      <el-table-column label="价格" width="200" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.price > 0">{{scope.row.price}}元/{{scope.row.unit}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button-group>
            <el-button
                size="small"
                @click="changestatus(scope)"
                v-if="scope.row.status == 1"
                type="danger"
            >停用</el-button>
            <el-button size="small" @click="changestatus(scope)" v-else type="success">启用</el-button>
            <el-button size="small" @click="mdfy(scope)" type="primary">修改</el-button>
          </el-button-group>
          <el-button-group v-if="scope.row.pid == 0">
            <el-button size="small" @click="addtype(scope)">添加子类</el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :visible.sync="addTypeDiv">
      <el-form :model="mdfytype" :rules="rules" ref="mdfytype">
        <el-form-item
            label="大分类"
            v-if="act == 'add' && mdfytype.pid>0"
            :label-width="formLabelWidth"
            class="text-left"
        >{{crntPTypeName}}</el-form-item>
        <el-form-item label="名称" :label-width="formLabelWidth" prop="type">
          <el-input v-model="mdfytype.type" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="价格" :label-width="formLabelWidth" prop="price" v-if="mdfytype.pid>0">
          <el-input v-model="mdfytype.price" autocomplete="off" type="number"></el-input>
        </el-form-item>
        <el-form-item label="计价单位" :label-width="formLabelWidth" prop="unit" v-if="mdfytype.pid>0">
          <el-input v-model="mdfytype.unit" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addTypeDiv = false">取 消</el-button>
        <el-button type="primary" @click="add">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [], //类型列表
      addTypeDiv: false, //添加类型窗口
      mdfytype: {
        type: "", //编辑的项目名称
        price: 0, //子类型价格
        id: 0,
        unit: 0,
        pid:0
      },
      act: "add",
      formLabelWidth: "150px",
      crntscope: [], //当前编辑对象
      subing:false,
      rules: {
        type: [
          { required: true, message: "请输入服务项目名称", trigger: "blur" },
          { min: 2, message: "名称不能小于两个汉字", trigger: "blur" },
        ],
        price: [{ required: true, message: "请输入服务单价", trigger: "blur" }],
        unit: [{ required: true, message: "请输入计量单位", trigger: "blur" }],
      },
    };
  },
  computed: {},
  methods: {
    getlist: function () {
      this.$axios({
        url: this.HOST + "/jiameng/jztype/getList",
        method: "post",
        data: {},
      }).then((res) => {
        if (res.data.status == 1) {
          this.list = res.data.list;
        }
      });
    },
    changestatus(scope) {
      let status = scope.row.status == 1 ? 2 : 1;
      this.$axios({
        method: "post",
        url: this.HOST + "/jiameng/jztype/setStatus",
        data: {
          id: scope.row.id,
          status: status,
        },
      }).then((res) => {
        if (res.data.status == 1) {
          scope.row.status = status;
        }
      });
    },
    add() { console.log(this.subing);
      if(this.subing == true) return;
      this.subing = true
      this.$axios({
        method: "post",
        url: this.HOST + "/jiameng/jztype/addtype",
        data: {
          info: this.mdfytype,
        },
      }).then((res) => {
        if (res.data.status == 1) {
          this.addTypeDiv = false;
          this.subing = false;
          this.getlist();
          this.mdfytype = {
            type: "", //编辑的项目名称
            price: 0, //子类型价格
            id: 0,
            unit: 0,
            pid:0
          }
        }else{
          this.subing = false;
        }
      });
    },
    addtype(scope) {
      if (this.$refs.mdfytype !== undefined) {
        this.$refs.mdfytype.resetFields();
      }
      this.mdfytype.type = "";
      this.mdfytype.price = 0;
      this.mdfytype.id = 0;
      this.mdfytype.unit = '';
      this.mdfytype.pid = scope.row.id;
      this.crntPTypeName = scope.row.type;
      this.addTypeDiv = true;
      this.act = "add";
    },
    addnewtype(){
      this.mdfytype.type = "";
      this.mdfytype.price = 0;
      this.mdfytype.id = 0;
      this.mdfytype.unit = '';
      this.mdfytype.pid = 0;
      this.addTypeDiv = true;
      this.act = "add";
    },
    mdfy(scope) {
      if (this.$refs.mdfytype !== undefined) {
        this.$refs.mdfytype.resetFields();
      }
      this.crntscope = scope.row;
      this.mdfytype.type = scope.row.type;
      this.mdfytype.price = scope.row.price;
      this.mdfytype.id = scope.row.id;
      this.mdfytype.unit = scope.row.unit;
      this.addTypeDiv = true;
      this.act = "mdfy";
    },
    formatStatus(row, column) {
      if(row.status == 1){
        return '正常';
      }else{
        return '停用';
      }
    },
  },
  created: function () {
    this.getlist();
  },
};
</script>

<style>
.el-table .cell {
  white-space: pre-line;
}
</style>